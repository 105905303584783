:root {
  --primary: rgb(7, 205, 255);
}

.custom-btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-bottom: 10%;
}

.btn--primary {
  background-color: var(--primary);
  color: #ffffff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.resume-btn {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: rgba(255, 255, 255, 0.842);
  color: rgb(1, 112, 139);
  transition: all 0.3s ease-out;
}
