.about-me {
  padding: 4rem;
  background: #fff;
  color: #2a2b33;
}

h1 {
  text-align: center;
  font-size: 3rem;
  color: #74bcff;
}

#aboutMeImage {
  height: 350px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15);
  border-radius: 50px !important;
  margin-left: 30%;
}

.resume-btn {
  background: rgb(255, 255, 255);
  color: rgb(7, 205, 255);
  text-decoration: none !important;
  font-size: 20px;
  border-radius: 1rem;
}

.resume-btn:hover {
  background: rgb(7, 205, 255);
  color: rgb(255, 255, 255);
}

@media only screen and (max-width: 768px) {
  #aboutMeImage {
    height: 250px;
    margin-left: 15%;
    margin-bottom: 30%;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  #aboutMeImage {
    height: 350px;
    margin-left: 150px;
    margin-bottom: 10%;
  }
}

@media only screen and (min-width: 990px) {
  #aboutMeImage {
    height: 350px;
    margin-left: 10vw;
  }
}
