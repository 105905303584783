.portfolio {
  padding: 4rem;
  background: #ebf5ff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
}

p {
  color: rgba(80, 85, 88, 0.842);
}

.title {
  font-size: 3em;
  text-align: center;
  padding-bottom: 4rem;
}

h3 {
  padding-top: 0.25em;
}

h5 {
  padding-top: 0.5em;
}

.vertical-timeline-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: #2a81b7be;
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 2em;
  }

  h3 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }
}
